/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.text-bold{
  font-weight: bold;
}
.border-box{
  border: 1px solid #000000;
}
.border-box-horizontal{
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.border-bottom{
  border-bottom: 1px solid #000000!important;
}
.border-right{
  border-right: 1px solid #000000!important;
}
.border-top{
  border-top: 1px solid #000000!important;
}
.border-bottom-none{
  border-bottom: none;
}
.border-right-none{
  border-right: none;
}
.border-top-none{
  border-top: none;
}
.text-nowrap{
  white-space: nowrap;
}
